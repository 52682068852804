import React, { ReactNode, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import type { DefaultError } from '@tanstack/query-core';
import axios, { SuccessResponse } from '../../../libraries/axios/axios';
import { authTokenState } from '../../../atoms/auth/auth.atom';
import {
  clearLocalStorageValue,
  getLocalStorageValue,
  LocalStorageKey,
} from '../../../utils/localStorage/localStorage';
import { TokenModel } from '../../../domain/Auth';
import {
  ProgressFactory,
  ProgressVariant,
} from '../../common/Progress/ProgressFactory';
import { currentUserState } from '../../../atoms/user/user.atom';
import { CurrentUser } from '../../../domain/User';
import { apiRoutes } from '../../../config/api/apiRoutes';
import { layoutExtendedState } from '../../../atoms/layout/layout.atom';
import { UserSettingsLayoutType } from '../../../domain/UserSettings';

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isInitCompleted, setIsInitCompleted] = useState(false);

  const [authStateValue, setAuthStateValue] = useRecoilState(authTokenState);
  const [currentUser, setCurrentUserState] = useRecoilState(currentUserState);
  const setLayoutExtendedState = useSetRecoilState(layoutExtendedState);

  const { mutate } = useMutation<SuccessResponse<CurrentUser>, DefaultError>({
    mutationFn: () => axios.get(apiRoutes.users.me),
    onSuccess: (response) => setCurrentUserState(response.data),
    onSettled: () => setIsInitCompleted(true),
  });

  useEffect(() => {
    setLayoutExtendedState(
      currentUser?.userSettings?.layoutType === UserSettingsLayoutType.FULL,
    );
  }, [currentUser]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error.response.status === 401 &&
          error.response.config.url !== '/token'
        ) {
          setAuthStateValue(null);
          clearLocalStorageValue(LocalStorageKey.token);
        }
        return Promise.reject(error);
      },
    );
  }, []);

  useEffect(() => {
    setAuthStateValue(getLocalStorageValue<TokenModel>(LocalStorageKey.token));
  }, []);

  useEffect(() => {
    if (authStateValue) {
      mutate();

      const intervalId = setInterval(() => {
        mutate();
      }, 5000);

      return () => clearInterval(intervalId);
    } else {
      setIsInitCompleted(true);
    }
  }, [authStateValue]);

  if (!isInitCompleted) {
    return <ProgressFactory variant={ProgressVariant.linear} />;
  }

  return <>{children}</>;
};

export default AuthProvider;
