import { useResetRecoilState } from 'recoil';
import { authTokenState } from '../../../atoms/auth/auth.atom';
import { currentUserState } from '../../../atoms/user/user.atom';

const useResetState = () => {
  const resetAuth = useResetRecoilState(authTokenState);
  const resetCurrentUser = useResetRecoilState(currentUserState);

  const resetAll = () => {
    resetAuth();
    resetCurrentUser();
  };

  return resetAll;
};

export default useResetState;
