import { atom } from 'recoil';
import {
  getLocalStorageValue,
  LocalStorageKey,
} from '../../utils/localStorage/localStorage';

export const selectedLanguageState = atom<string>({
  key: 'languageState',
  default: getLocalStorageValue(LocalStorageKey.language) ?? 'GB',
});
