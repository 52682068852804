import React, { useMemo } from 'react';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import styles from './UserInfo.module.scss';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../../../../../atoms/user/user.atom';
import { getAssetLocation } from '../../../../../utils/asset/asset.util';
import { AssetTypes } from '../../../../../domain/Asset';
import ababaTechLogo from '../../../../../assets/ababa-tech-logo.webp';
import { sidebarExtendedState } from '../../../../../atoms/layout/layout.atom';
import { useWindowWidth } from '@react-hook/window-size';
import cx from 'classnames';
import _ from 'lodash';
import { colors } from '../../../../../config/styles/colors';

export const UserInfo = () => {
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const currentUser = useRecoilValue(currentUserState);
  const screenWidth = useWindowWidth();

  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  return (
    <Stack
      className={cx(styles.userInfoContainer, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      <Stack className={styles.userInfoContainerInner}>
        <Box className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            src={
              currentUser?.profile.image
                ? getAssetLocation(
                    currentUser?.profile.image,
                    AssetTypes.IMAGE_THUMB_SMALL,
                  )
                : undefined
            }
          ></Avatar>
        </Box>
        <Stack className={styles.nameContainer}>
          <Typography
            className={styles.name}
            variant="subtitle2"
            style={{ color: colors.textColor }}
          >
            {_.capitalize(currentUser?.profile.firstName)}{' '}
            {_.capitalize(currentUser?.profile.lastName)}
          </Typography>
          <Typography
            className={styles.email}
            variant="body2"
            style={{ color: colors.sidebarGroupColor }}
          >
            {currentUser?.identity}
          </Typography>
        </Stack>
        <Divider style={{ borderColor: colors.dividerBorderColorLightest }} />
        <Stack className={styles.systemContainer}>
          <Box className={styles.logoContainer}>
            <a
              href="https://ababa.tech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ababaTechLogo} />
            </a>
          </Box>
          <Typography
            className={styles.version}
            variant="body2"
            style={{ color: colors.sidebarGroupColor }}
          >
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UserInfo;
