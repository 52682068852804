export enum HttpMethod {
  'GET' = 'GET',
  'POST' = 'POST',
  'PATCH' = 'PATCH',
  'DELETE' = 'DELETE',
}

export type MappedRoleAccess = {
  url: string;
  method: HttpMethod;
  roles?: string[];
};

export enum RoleType {
  'ADMIN' = 'ADMIN',
  'SUPER_ADMIN' = 'SUPER_ADMIN',
}
