import React from 'react';
import LinearProgress from './LinearProgress/LinearProgress';

export enum ProgressVariant {
  'linear' = 'linear',
}

type ProgressFactoryProps = {
  variant: ProgressVariant;
};

export const ProgressFactory = ({ variant }: ProgressFactoryProps) => {
  switch (variant) {
    case ProgressVariant.linear:
      return <LinearProgress />;
    default:
      return null;
  }
};
