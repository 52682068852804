import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Divider,
  IconButton as MuiIconButton,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import styles from './UserPopover.module.scss';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../../../../../atoms/user/user.atom';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../config/routes/routes';
import cx from 'classnames';
import useResetState from '../../../../hooks/useResetState/useResetState';
import {
  clearLocalStorageValue,
  LocalStorageKey,
} from '../../../../../utils/localStorage/localStorage';
import { getAssetLocation } from '../../../../../utils/asset/asset.util';
import { AssetTypes } from '../../../../../domain/Asset';
import { translationsState } from '../../../../../atoms/translation/translation.atom';
import { translate } from '../../../../../utils/translation/translation';
import _ from 'lodash';
import { colors } from '../../../../../config/styles/colors';

export const UserPopover = () => {
  const translations = useRecoilValue(translationsState);
  const navigate = useNavigate();

  const [userAnchorEl, setUserAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const currentUser = useRecoilValue(currentUserState);
  const resetState = useResetState();

  const handleItemClick = (callback: Function) => {
    handleClosePopover();

    setTimeout(() => {
      callback();
    }, 100);
  };

  const handleLogoutClick = () => {
    clearLocalStorageValue(LocalStorageKey.token);
    handleClosePopover();
    resetState();
  };

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchorEl(e.currentTarget);
    setIsOpen(true);
  };

  const handleClosePopover = () => {
    setIsOpen(false);
    setUserAnchorEl(null);
  };

  return (
    <>
      <MuiIconButton
        onClick={(e) => handleOpenPopover(e)}
        size="small"
        className={styles.profileButton}
        style={{ color: colors.textSubtitleColor }}
      >
        <Avatar
          src={
            currentUser?.profile.image
              ? getAssetLocation(
                  currentUser?.profile.image,
                  AssetTypes.IMAGE_THUMB_SMALL,
                )
              : undefined
          }
          className={styles.avatar}
          style={{ borderColor: colors.backgroundColor }}
        />
      </MuiIconButton>
      <Popover
        open={isOpen}
        anchorEl={userAnchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <>
          <span
            className={styles.popoverArrow}
            style={{
              borderColor: colors.popoverArrowBorderColor,
              backgroundColor: colors.popoverArrowBackgroundColor,
            }}
          />
          <Box className={styles.userNameContainer}>
            <Typography variant="subtitle1">
              {_.capitalize(currentUser?.profile.firstName)}{' '}
              {_.capitalize(currentUser?.profile.lastName)}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: colors.textSubtitleColor }}
            >
              {currentUser?.identity}
            </Typography>
          </Box>
          <Divider className={styles.divider} />
          <Stack p={'8px'}>
            <MenuItem
              className={styles.profileItem}
              onClick={() => handleItemClick(() => navigate(routes.homepage))}
            >
              {translate(translations, 'USER_POPOVER.HOME')}
            </MenuItem>
            <MenuItem
              className={styles.profileItem}
              onClick={() =>
                handleItemClick(() => navigate(routes.admin.profile))
              }
            >
              {translate(translations, 'USER_POPOVER.PROFILE')}
            </MenuItem>
            <MenuItem
              className={styles.profileItem}
              onClick={() =>
                handleItemClick(() => navigate(routes.admin.profileSettings))
              }
            >
              {translate(translations, 'USER_POPOVER.SETTINGS')}
            </MenuItem>
          </Stack>
          <Divider className={styles.divider} />
          <MenuItem
            className={cx(styles.profileItem, styles.logoutButton)}
            style={{ color: colors.inputErrorColor }}
            onClick={handleLogoutClick}
          >
            {translate(translations, 'USER_POPOVER.LOGOUT')}
          </MenuItem>
        </>
      </Popover>
    </>
  );
};

export default UserPopover;
