import React from 'react';
import { AppBar, Box } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { sidebarExtendedState } from '../../../../atoms/layout/layout.atom';
import styles from './AdminNavigation.module.scss';
import UserPopover from './UserPopover/UserPopover';
import { useWindowWidth } from '@react-hook/window-size';
import NavigationDrawer from './NavigationDrawer/NavigationDrawer';
import LanguagePopover from './LanguagePopover/LanguagePopover';
import SettingsButton from './SettingsButton/SettingsButton';
import { colors } from '../../../../config/styles/colors';
import { currentUserState } from '../../../../atoms/user/user.atom';
import { RoleType } from '../../../../domain/Role';

export const AdminNavigation = () => {
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const currentUser = useRecoilValue(currentUserState);
  const screenWidth = useWindowWidth();

  return (
    <AppBar
      elevation={4}
      position="fixed"
      color="transparent"
      classes={{
        root: styles.adminNavigationContainer,
      }}
      style={
        screenWidth >= 1200
          ? {
              width: `calc(100% - ${sidebarExtendedValue ? '281px' : '89px'})`,
              backgroundColor: colors.topNavigationBackgroundColor,
            }
          : { backgroundColor: colors.topNavigationBackgroundColor }
      }
    >
      <Box className={styles.adminNavigationInner}>
        <Box className={styles.leftActions}>
          <NavigationDrawer />
        </Box>
        <Box className={styles.rightActions}>
          {currentUser?.roles.includes(RoleType.SUPER_ADMIN) && (
            <SettingsButton />
          )}
          <LanguagePopover />
          <UserPopover />
        </Box>
      </Box>
    </AppBar>
  );
};

export default AdminNavigation;
