import React, { useMemo } from 'react';
import styles from './AdminSidebar.module.scss';
import SidebarGroup, { SidebarSection } from './SidebarGroup/SidebarGroup';
import { routes } from '../../../../config/routes/routes';
import Speed from '@mui/icons-material/Speed';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import AccountBox from '@mui/icons-material/AccountBox';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../../atoms/translation/translation.atom';
import { translate } from '../../../../utils/translation/translation';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import logo from '../../../../assets/logo.png';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { sidebarExtendedState } from '../../../../atoms/layout/layout.atom';
import { useWindowWidth } from '@react-hook/window-size';
import UserInfo from './UserInfo/UserInfo';
import { colors } from '../../../../config/styles/colors';
import { apiRoutes } from '../../../../config/api/apiRoutes';
import { roleAccessState } from '../../../../atoms/auth/auth.atom';
import { HttpMethod } from '../../../../domain/Role';
import PeopleIcon from '@mui/icons-material/People';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

export const AdminSidebar = () => {
  const translations = useRecoilValue(translationsState);
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const roleAccessValue = useRecoilValue(roleAccessState);
  const screenWidth = useWindowWidth();

  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  const sidebarWidth = useMemo(() => {
    if (screenWidth < 1200) {
      return isCollapsed ? 88 : 320;
    }
    return isCollapsed ? 88 : 280;
  }, [isCollapsed, screenWidth]);

  const sidebarItems: SidebarSection[] = useMemo(() => {
    return [
      {
        title: translate(translations, 'SIDEBAR.GROUP_GENERAL'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.DASHBOARD'),
            icon: <Speed />,
            url: routes.admin.dashboard,
          },
          {
            title: translate(translations, 'SIDEBAR.TASKS'),
            icon: <AssignmentIcon />,
            url: routes.admin.tasks.list,
            apiUrl: apiRoutes.tasks.list,
          },
          {
            title: translate(translations, 'SIDEBAR.TASKS'),
            icon: <AssignmentIcon />,
            url: routes.admin.clientTasks.list,
            apiUrl: apiRoutes.tasks.kanbanClient,
          },
          {
            title: translate(translations, 'SIDEBAR.CLIENTS'),
            icon: <PeopleIcon />,
            url: routes.admin.clients.list,
            apiUrl: apiRoutes.clients.list,
          },
          {
            title: translate(translations, 'SIDEBAR.PROJECTS'),
            icon: <AccountTreeIcon />,
            url: routes.admin.projects.list,
            apiUrl: apiRoutes.projects.list,
          },
          {
            title: translate(translations, 'SIDEBAR.EMPLOYEES'),
            icon: <BadgeIcon />,
            url: routes.admin.employees.list,
            apiUrl: apiRoutes.employees.list,
          },
          {
            title: translate(translations, 'SIDEBAR.INVOICES'),
            icon: <ReceiptIcon />,
            url: routes.admin.invoices.list,
            apiUrl: apiRoutes.invoices.list,
          },
          {
            title: translate(translations, 'SIDEBAR.WORK_LOGS'),
            icon: <AccessTimeIcon />,
            url: routes.admin.taskWorkLogs.list,
            apiUrl: apiRoutes.taskWorkLogs.list,
          },
        ],
      },
      {
        title: translate(translations, 'SIDEBAR.GROUP_SETTINGS'),
        items: [
          {
            title: translate(translations, 'SIDEBAR.USERS'),
            icon: <AccountBox />,
            url: routes.admin.users.list,
            apiUrl: apiRoutes.users.list,
          },
          {
            title: translate(translations, 'SIDEBAR.TRANSLATIONS'),
            icon: <SpellcheckIcon />,
            url: routes.admin.translations.list,
            apiUrl: apiRoutes.translations.list,
          },
        ],
      },
    ].map((sidebarSection: SidebarSection) => {
      const accessibleSidebarItems = sidebarSection.items.filter(
        (sidebarItem) => {
          if (!sidebarItem.apiUrl) {
            return true;
          }

          return roleAccessValue.some(
            (roleAccessItem) =>
              roleAccessItem.url === `/api/${sidebarItem.apiUrl}` &&
              roleAccessItem.method === HttpMethod.GET,
          );
        },
      );

      return { ...sidebarSection, items: accessibleSidebarItems };
    });
  }, [roleAccessValue]);

  return (
    <motion.div
      animate={{ width: sidebarWidth }}
      exit={{ width: 88 }}
      className={cx(styles.sidebarContainer, {
        [styles.collapsed]: isCollapsed,
      })}
      transition={{
        duration: 0.5,
        mass: 0.5,
        restDelta: 0.001,
        ease: [0, 0, 0.58, 1],
      }}
      style={{
        borderColor: colors.dividerBorderColor,
        backgroundColor: colors.backgroundColor,
      }}
    >
      <Box className={styles.sidebarContainerInner}>
        <Box data-simplebar className={styles.sidebarContainerScrollable}>
          <Link to={'/'} className={styles.logoContainer}>
            <img src={logo} />
          </Link>
          <nav className={styles.navigation}>
            {sidebarItems
              .filter((sidebarSection) => sidebarSection.items.length > 0)
              .map((sidebarSection) => (
                <SidebarGroup
                  key={sidebarSection.title}
                  sidebarSection={sidebarSection}
                />
              ))}
          </nav>
          <UserInfo />
          <Box className={styles.postNavigation} />
        </Box>
      </Box>
    </motion.div>
  );
};

export default AdminSidebar;
