import React, { useEffect, useState } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import RouteProvider from './app/providers/RouteProvider/RouteProvider';
import AuthProvider from './app/providers/AuthProvider/AuthProvider';
import { theme } from './config/styles/theme';
import TranslationProvider from './app/providers/TranslationProvider/TranslationProvider';
import { Toaster } from 'sonner';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import NewVersion from './app/common/NewVersion/NewVersion';
import HealthProvider from './app/providers/HealthProvider/HealthProvider';
import {
  clearLocalStorageValue,
  getLocalStorageValue,
  LocalStorageKey,
  setLocalStorageValue,
} from './utils/localStorage/localStorage';

const queryClient = new QueryClient();

const App = () => {
  const [showNotification, setShowNotification] = useState(false);
  const [needsRestart] = useState(
    getLocalStorageValue(LocalStorageKey.needsRestart) ?? 0,
  );

  useEffect(() => {
    if (needsRestart && needsRestart > 0) {
      setLocalStorageValue(LocalStorageKey.needsRestart, needsRestart - 1);

      const timeoutRef = setTimeout(() => {
        window.location.reload();
      }, 300);

      return () => clearTimeout(timeoutRef);
    } else {
      clearLocalStorageValue(LocalStorageKey.needsRestart);
    }
  }, []);

  const handleDisplayShowNotification = () => {
    setShowNotification(true);
    setLocalStorageValue(LocalStorageKey.version, true);
  };

  const handleRefresh = () => {
    setLocalStorageValue(LocalStorageKey.needsRestart, 2);

    navigator.serviceWorker.getRegistration().then((registration) => {
      if (!registration) {
        window.location.reload();
        return;
      }

      registration.update().then(() => {
        window.location.reload();
      });
    });
  };

  useEffect(() => {
    serviceWorkerRegistration.register({
      onSuccess: () => {},
      onUpdate: () => {},
    });
  }, []);

  if (needsRestart) {
    return null;
  }

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
    >
      <MuiThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <RecoilRoot>
            <TranslationProvider>
              <Toaster />
              <AuthProvider>
                <HealthProvider onHasNewVersion={handleDisplayShowNotification}>
                  <RouteProvider />
                  {showNotification && <NewVersion onRefresh={handleRefresh} />}
                </HealthProvider>
              </AuthProvider>
            </TranslationProvider>
          </RecoilRoot>
        </QueryClientProvider>
      </MuiThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
