export interface Asset {
  id: number;
  url: string;
  childAssets?: Asset[];
  type: AssetTypes;
}

export enum AssetTypes {
  IMAGE = 'IMAGE',
  IMAGE_OPTIMIZED = 'IMAGE_OPTIMIZED',
  IMAGE_THUMB_SMALL = 'IMAGE_THUMB_SMALL',
  IMAGE_THUMB_MEDIUM = 'IMAGE_THUMB_MEDIUM',
  IMAGE_THUMB_LARGE = 'IMAGE_THUMB_LARGE',
  FILE = 'FILE',
}
