import React from 'react';
import { IconButton as MuiIconButton } from '@mui/material';
import styles from './SettingsButton.module.scss';
import settingsIcon from '../../../../../assets/icons/settings.svg';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../config/routes/routes';

export const SettingsButton = () => {
  const navigate = useNavigate();

  return (
    <MuiIconButton
      onClick={() => navigate(routes.admin.settings)}
      size="small"
      className={styles.settingsButton}
    >
      <img src={settingsIcon} className={styles.icon} />
    </MuiIconButton>
  );
};

export default SettingsButton;
