import React from 'react';
import { Button, Box } from '@mui/material';
import styles from './NewVersion.module.scss';
import { useRecoilValue } from 'recoil';
import { translationsState } from '../../../atoms/translation/translation.atom';
import { translate } from '../../../utils/translation/translation';
import { colors } from '../../../config/styles/colors';

type NewVersionProps = {
  onRefresh: () => void;
};

export const NewVersion = ({ onRefresh }: NewVersionProps) => {
  const translations = useRecoilValue(translationsState);

  return (
    <Box
      className={styles.newVersion}
      style={{
        color: colors.textColor,
        backgroundColor: colors.backgroundColor,
      }}
    >
      {translate(translations, 'NEW_VERSION.TITLE')}
      <Button type="button" onClick={onRefresh} variant="contained">
        {translate(translations, 'NEW_VERSION.UPDATE')}
      </Button>
    </Box>
  );
};

export default NewVersion;
