import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import styles from './ErrorFallback.module.scss';
import { colors } from '../../../config/styles/colors';

type ErrorFallbackType = {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  onButtonClick: () => void;
};

export const ErrorFallback = ({
  title,
  subtitle,
  image,
  buttonText,
  onButtonClick,
}: ErrorFallbackType) => {
  return (
    <Stack className={styles.errorFallbackContainer}>
      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        className={styles.subtitle}
        style={{ color: colors.textSubtitleColor }}
      >
        {subtitle}
      </Typography>
      <Box className={styles.errorImage}>
        <img src={image} />
      </Box>
      <Button variant="contained" onClick={onButtonClick}>
        {buttonText}
      </Button>
    </Stack>
  );
};

export default ErrorFallback;
