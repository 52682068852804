import React, { useMemo, useState } from 'react';
import styles from './SidebarGroup.module.scss';
import { Box, Collapse } from '@mui/material';
import SidebarButton, { SidebarItem } from './SidebarButton/SidebarButton';
import { useRecoilValue } from 'recoil';
import { sidebarExtendedState } from '../../../../../atoms/layout/layout.atom';
import cx from 'classnames';
import { useWindowWidth } from '@react-hook/window-size';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { colors } from '../../../../../config/styles/colors';

export type SidebarSection = {
  title: string;
  items: SidebarItem[];
};

type SidebarGroupProps = {
  sidebarSection: SidebarSection;
};

export const SidebarGroup = ({ sidebarSection }: SidebarGroupProps) => {
  const sidebarExtendedValue = useRecoilValue(sidebarExtendedState);
  const screenWidth = useWindowWidth();

  const [isOpen, setIsOpen] = useState(true);
  const isCollapsed = useMemo(() => {
    if (screenWidth < 1200) {
      return false;
    }

    return !sidebarExtendedValue;
  }, [sidebarExtendedValue, screenWidth]);

  return (
    <Box
      key={sidebarSection.title}
      className={cx(styles.navigationGroup, {
        [styles.collapsed]: isCollapsed,
      })}
    >
      {!isCollapsed && (
        <li
          className={styles.navigationGroupName}
          onClick={() => setIsOpen((prev) => !prev)}
          style={{ color: colors.sidebarGroupColor }}
        >
          {isOpen && <ExpandMoreIcon className={styles.expandIcon} />}
          {!isOpen && <ChevronRightIcon className={styles.expandIcon} />}
          <Box component="span">{sidebarSection.title}</Box>
        </li>
      )}
      <Collapse in={isOpen || !sidebarExtendedValue}>
        {sidebarSection.items.map((item, index) => (
          <SidebarButton key={`${item.title}-${index}`} item={item} />
        ))}
      </Collapse>
    </Box>
  );
};

export default SidebarGroup;
