import React from 'react';
import AdminLayout from './AdminLayout/AdminLayout';
import AuthLayout from './AuthLayout/AuthLayout';

export enum PageLayoutType {
  'admin' = 'admin',
  'auth' = 'auth',
}

type LayoutFactoryProps = {
  variant: PageLayoutType;
};

export const LayoutFactory = ({ variant }: LayoutFactoryProps) => {
  switch (variant) {
    case PageLayoutType.admin:
      return <AdminLayout />;
    case PageLayoutType.auth:
      return <AuthLayout />;
    default:
      return null;
  }
};
