import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { Box, Paper, Stack } from '@mui/material';
import styles from './AuthLayout.module.scss';
import { routes } from '../../../config/routes/routes';
import { colors } from '../../../config/styles/colors';
import logo from '../../../assets/logo.png';
import overlay from '../../../assets/overlay.jpg';
import ababaTechLogo from '../../../assets/ababa-tech-logo.webp';

export const AuthLayout = () => {
  return (
    <Box className={styles.authLayoutContainer}>
      <Stack component="main" className={styles.mainContainer}>
        <Stack className={styles.authInnerPart}>
          <Box>
            <Link
              to={routes.homepage}
              className={styles.logoMainLink}
              style={{ color: colors.buttonContainedPrimaryColor }}
            >
              <Box className={styles.logoLink}>
                <img src={logo} />
              </Box>
            </Link>
            <Paper
              elevation={0}
              className={styles.outletContainer}
              style={{ backgroundColor: colors.backgroundColor }}
            >
              <Outlet />
            </Paper>
          </Box>
          <Stack className={styles.systemContainer}>
            <Box className={styles.logoContainer}>
              <a
                href="https://ababa.tech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ababaTechLogo} />
              </a>
            </Box>
          </Stack>
        </Stack>
        <Stack className={styles.mainImageContainer}>
          <img src={overlay} />
        </Stack>
      </Stack>
    </Box>
  );
};

export default AuthLayout;
